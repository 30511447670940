h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong, td strong {
  font-weight: 900;
}

h1, h2, h3, h4, h5, h6, li, p {
  margin: 0 auto;
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  line-height: 1em;
}

h1 sup, h2 sup, h3 sup, h4 sup, h5 sup, h6 sup, li sup, p sup {
  font-size: 50%;
  top: -.75em;
}

h1 {
  font-size: 30px;
  line-height: 1.2em;
}

@media (width >= 768px) {
  h1 {
    font-size: 36px;
  }
}

@media (width >= 991px) {
  h1 {
    font-size: 48px;
  }
}

h2 {
  font-size: 24px;
  line-height: 1.2em;
}

@media (width >= 768px) {
  h2 {
    font-size: 30px;
  }
}

@media (width >= 991px) {
  h2 {
    font-size: 36px;
  }
}

h3 {
  font-size: 20px;
}

@media (width >= 768px) {
  h3 {
    font-size: 24px;
  }
}

@media (width >= 991px) {
  h3 {
    font-size: 30px;
  }
}

h4 {
  font-size: 18px;
}

@media (width >= 768px) {
  h4 {
    font-size: 20px;
  }
}

@media (width >= 991px) {
  h4 {
    font-size: 24px;
  }
}

h5 {
  font-size: 16px;
}

@media (width >= 768px) {
  h5 {
    font-size: 18px;
  }
}

@media (width >= 991px) {
  h5 {
    font-size: 20px;
  }
}

h6 {
  font-size: 15px;
}

@media (width >= 768px) {
  h6 {
    font-size: 16px;
  }
}

@media (width >= 991px) {
  h6 {
    font-size: 18px;
  }
}

p, li {
  font-size: 15px;
  line-height: 1.6em;
}

@media (width >= 991px) {
  p, li {
    font-size: 16px;
  }
}

img {
  display: inline-block;
}

.bg-songs {
  background-color: #17d1fa;
}

.bg-coaches {
  background-color: #fe9f13;
}

.text-songs {
  color: #17d1fa;
}

.text-coaches {
  color: #fe9f13;
}

.text-light-navy {
  color: #a1afc9;
}

.imgfilter-coaches {
  filter: invert() brightness(.65) sepia() saturate(24) hue-rotate(363deg);
}

.imgfilter-songs {
  filter: invert() brightness(.745) sepia() saturate(32) hue-rotate(150deg);
}

.imgfilter-method {
  filter: invert() brightness(.172) sepia() saturate(30) hue-rotate(342deg);
}

.lazyload {
  opacity: 0;
}

.lazyloading {
  opacity: 1;
  transition: opacity .3s;
}

.join i {
  transition: all .3s;
  position: relative;
  right: 0;
}

.join:hover i {
  right: -3px;
}

.join.smaller {
  padding: 8px 30px;
  font-size: 16px;
}

@media (width >= 768px) {
  .join.smaller {
    padding: 11px 30px;
    font-size: 18px;
  }
}

.join.smaller.outline {
  padding: 8px 28px 6px;
  font-size: 16px;
}

@media (width >= 768px) {
  .join.smaller.outline {
    padding: 10px 28px 8px;
    font-size: 18px;
  }
}

.join.coaches {
  color: #000;
  background-color: #fe9f13;
}

.join.coaches:hover, .join.coaches:focus {
  color: #000;
  background: #feb446;
}

.join.musora {
  color: #000;
  background-color: #ffae00;
}

.join.musora:hover, .join.musora:focus {
  color: #000;
  background: #ffbe33;
}

.join.promo {
  background: #d61922;
}

.join.promo:hover, .join.promo:focus {
  background: #e83a42;
}

.join.outline {
  color: #fff;
  background: none;
  border: 1px solid #fff;
  padding: 6px 12px;
  outline-style: none !important;
}

@media (width >= 768px) {
  .join.outline {
    border-width: 2px;
    padding: 11px 30px;
  }
}

.join.outline:hover, .join.outline:focus {
  color: #000;
  background: #fff;
}

.join.outline.black {
  color: #000;
  border-color: #000;
}

.join.outline.black:hover, .join.outline.black:focus {
  color: #fff;
  background: #000;
}

.join.outline.light-navy {
  color: #a1afc9;
  border-color: #a1afc9;
}

.join.outline.light-navy:hover, .join.outline.light-navy:focus {
  color: #000;
  background: #a1afc9;
}

.join.outline.method, .join.outline.pianote {
  color: #f61a30;
  border-color: #f61a30;
}

.join.outline.method:hover, .join.outline.method:focus, .join.outline.pianote:hover, .join.outline.pianote:focus {
  color: #fff;
  background: #f61a30;
}

.join.outline.songs {
  color: #17d1fa;
  border-color: #17d1fa;
}

.join.outline.songs:hover, .join.outline.songs:focus {
  color: #fff;
  background: #17d1fa;
}

.join.outline.coaches {
  color: #fe9f13;
  border-color: #fe9f13;
}

.join.outline.coaches:hover, .join.outline.coaches:focus {
  color: #fff;
  background: #fe9f13;
}

.join.outline.musora {
  color: #ffae00;
  border-color: #ffae00;
}

.join.outline.musora:hover, .join.outline.musora:focus {
  color: #fff;
  background: #ffae00;
}

.join.outline.promo {
  color: #d61922;
  border-color: #d61922;
}

.join.outline.promo:hover, .join.outline.promo:focus {
  color: #fff;
  background: #d61922;
}

.play-button {
  cursor: pointer;
  color: #fff;
  background: #0009;
  border: 3px solid #fff;
  border-radius: 200px;
  outline: none;
  padding: 18px 22px;
  font-size: 29px;
  line-height: 1em;
  transition: opacity .3s;
  display: inline-block;
}

@media (width >= 768px) {
  .play-button {
    border-width: 4px;
    padding: 22px 27px;
    font-size: 35px;
  }
}

@media (width >= 991px) {
  .play-button {
    padding: 25px 30px;
    font-size: 39px;
  }
}

.play-button:hover {
  opacity: .8;
}

.play-button.smaller {
  border-width: 2px;
  padding: 14px 17px;
  font-size: 24px;
}

.header {
  height: 610px;
}

@media (width >= 768px) {
  .header {
    height: 600px;
  }
}

@media (width >= 991px) {
  .header {
    height: 750px;
  }
}

.header .header-image {
  background-position: 50% 0;
  background-size: cover;
}

@media (width >= 768px) {
  .header .header-image {
    background-position: 25% 0;
    background-size: 1600px;
  }
}

@media (width >= 991px) {
  .header .header-image {
    background-position: 10% 0;
    background-size: 1800px;
  }
}

.content-section {
  color: #fff;
  background: #01050f top / 1050px no-repeat;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
  overflow: hidden;
}

@media (width >= 768px) {
  .content-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (width >= 991px) {
  .content-section {
    background-size: 1550px;
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.content-section .dropdown .bg-drumeo {
  min-width: 32px;
}

@media (width >= 768px) {
  .content-section .dropdown .bg-drumeo {
    min-width: 83px;
  }
}

.content-section .dropdown.active .description {
  visibility: visible;
  opacity: 1;
  height: auto;
  max-height: 1000px;
}

.content-section .device-spread {
  max-width: 375px;
  min-height: 194px;
}

@media (width >= 768px) {
  .content-section .device-spread {
    max-width: 680px;
    min-height: 352px;
  }
}

@media (width >= 991px) {
  .content-section .device-spread {
    max-width: 920px;
    min-height: 476px;
  }
}

.content-section .device-spread .text-arrow {
  top: 118%;
}

.content-section .device-spread .text-arrow.desktop {
  left: 0;
}

@media (width >= 768px) {
  .content-section .device-spread .text-arrow.desktop {
    top: 7%;
    left: 21%;
  }
}

.content-section .device-spread .text-arrow.macbook {
  left: 25%;
}

@media (width >= 768px) {
  .content-section .device-spread .text-arrow.macbook {
    top: 35%;
    left: 1%;
  }
}

.content-section .device-spread .text-arrow.ipad {
  left: 50%;
}

@media (width >= 768px) {
  .content-section .device-spread .text-arrow.ipad {
    top: 36%;
    left: 98%;
  }
}

.content-section .device-spread .text-arrow.iphone {
  left: 75%;
}

@media (width >= 768px) {
  .content-section .device-spread .text-arrow.iphone {
    top: 65%;
    left: 101%;
  }
}

.content-section .slick-2 .flip-div, .content-section .slick-2 .flip-div .bg-image {
  padding-bottom: 145%;
}

.content-section .slick-2 .flip-div.flipped .front {
  transform: rotateY(180deg);
}

.content-section .slick-2 .flip-div.flipped .back {
  transform: rotateY(0);
}

.content-section .slick-2 .flip-div .back {
  transform: rotateY(-180deg);
}

.content-section .slick-2 .flip-div .front, .content-section .slick-2 .flip-div .back {
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform .8s;
}

@media (width >= 768px) {
  .content-section .flip-div {
    padding-bottom: 100%;
  }
}

.content-section .flip-div.flipped .front {
  transform: rotateY(180deg);
}

.content-section .flip-div.flipped .back {
  transform: rotateY(0);
}

.content-section .flip-div .back {
  transform: rotateY(-180deg);
}

.content-section .flip-div .bg-image {
  padding-bottom: 75%;
}

@media (width >= 768px) {
  .content-section .flip-div .bg-image {
    padding-bottom: 100%;
  }
}

.content-section .gradient-bg {
  height: 495px;
}

@media (width >= 768px) {
  .content-section .gradient-bg {
    height: 750px;
  }
}

@media (width >= 991px) {
  .content-section .gradient-bg {
    height: 935px;
  }
}

@keyframes songsAnimation {
  0% {
    transform: translate3d(-27%, 0, 0);
  }

  50% {
    transform: translate3d(0%, 0, 0);
  }

  100% {
    transform: translate3d(-27%, 0, 0);
  }
}

@keyframes songsAnimationReverse {
  0% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(-27%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.content-section .song-wrap {
  height: 625px;
  margin-bottom: -625px;
}

@media (width >= 768px) {
  .content-section .song-wrap {
    height: 875px;
    margin-bottom: -875px;
  }
}

@media (width >= 991px) {
  .content-section .song-wrap {
    height: 1075px;
    margin-bottom: -1075px;
  }
}

.content-section .song-row {
  height: 125px;
  font-size: 0;
  animation: 60s linear infinite songsAnimation;
}

@media (width >= 768px) {
  .content-section .song-row {
    height: 175px;
  }
}

@media (width >= 991px) {
  .content-section .song-row {
    height: 215px;
  }
}

.content-section .song-row:nth-child(2) {
  top: 125px;
}

@media (width >= 768px) {
  .content-section .song-row:nth-child(2) {
    top: 175px;
  }
}

@media (width >= 991px) {
  .content-section .song-row:nth-child(2) {
    top: 215px;
  }
}

.content-section .song-row:nth-child(3) {
  top: 250px;
}

@media (width >= 768px) {
  .content-section .song-row:nth-child(3) {
    top: 350px;
  }
}

@media (width >= 991px) {
  .content-section .song-row:nth-child(3) {
    top: 430px;
  }
}

.content-section .song-row:nth-child(4) {
  top: 375px;
}

@media (width >= 768px) {
  .content-section .song-row:nth-child(4) {
    top: 525px;
  }
}

@media (width >= 991px) {
  .content-section .song-row:nth-child(4) {
    top: 645px;
  }
}

.content-section .song-row:nth-child(5) {
  top: 500px;
}

@media (width >= 768px) {
  .content-section .song-row:nth-child(5) {
    top: 700px;
  }
}

@media (width >= 991px) {
  .content-section .song-row:nth-child(5) {
    top: 860px;
  }
}

.content-section .song-row:nth-child(odd) {
  animation-name: songsAnimationReverse;
}

.content-section .song-row img {
  vertical-align: top;
  width: 125px;
}

@media (width >= 768px) {
  .content-section .song-row img {
    width: 175px;
  }
}

@media (width >= 991px) {
  .content-section .song-row img {
    width: 215px;
  }
}

.content-section .half-tile-bg {
  background-size: 500px;
}

@media (width >= 768px) {
  .content-section .half-tile-bg {
    background-size: 520px;
  }
}

@media (width >= 1280px) {
  .content-section .half-tile-bg {
    background-size: 730px;
  }
}

.content-section .big-tile-bg {
  background-size: 550px;
}

@media (width >= 768px) {
  .content-section .big-tile-bg {
    background-size: 1050px;
  }
}

@media (width >= 1280px) {
  .content-section .big-tile-bg {
    background-size: 1400px;
  }
}

.content-section .feature-rotater .pic-wrap {
  width: 220px;
  min-height: 382px;
}

@media (width >= 768px) {
  .content-section .feature-rotater .pic-wrap {
    width: 280px;
    min-height: 461px;
  }
}

@media (width >= 991px) {
  .content-section .feature-rotater .pic-wrap {
    width: 400px;
    min-height: 639px;
  }
}

@media (width >= 768px) {
  .content-section .feature-rotater .side-pic.active {
    display: block;
  }
}

.content-section .feature-rotater .text-icon-wrap {
  color: #fff;
}

@media (width >= 768px) {
  .content-section .feature-rotater .text-icon-wrap {
    color: inherit;
  }
}

.content-section .feature-rotater .text-icon-wrap.active {
  color: #fff;
  opacity: 1 !important;
}

.content-section .method-levels .dropdown:nth-child(n+6) {
  visibility: hidden;
  opacity: 0;
  height: 0;
  margin-bottom: 0;
  transition: all .2s ease-in-out;
}

.content-section .method-levels.show-all .dropdown:nth-child(n+6) {
  visibility: visible;
  opacity: 1;
  height: auto;
  margin-bottom: .75rem;
}

.content-section .method-levels.show-all .join {
  display: none;
}

.content-section .course-tiles .course:nth-child(n+9) {
  visibility: hidden;
  opacity: 0;
  height: 0;
  margin-bottom: 0;
  transition: all .2s ease-in-out;
}

.content-section .course-tiles.show-all .course:nth-child(n+9) {
  visibility: visible;
  opacity: 1;
  height: auto;
  margin-bottom: .75rem;
}

.content-section .course-tiles.show-all .join {
  display: none;
}

.content-section .testimonials .testimonial:nth-child(n+3) {
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: all .2s ease-in-out;
}

@media (width >= 768px) {
  .content-section .testimonials .testimonial:nth-child(n+3) {
    visibility: visible;
    opacity: 1;
    height: auto;
  }

  .content-section .testimonials .testimonial:nth-child(n+7) {
    visibility: hidden;
    opacity: 0;
    height: 0;
    transition: all .2s ease-in-out;
  }
}

@media (width >= 991px) {
  .content-section .testimonials .testimonial:nth-child(n+7), .content-section .testimonials .testimonial:nth-child(n+8) {
    visibility: visible;
    opacity: 1;
    height: auto;
  }

  .content-section .testimonials .testimonial:nth-child(n+9) {
    visibility: hidden;
    opacity: 0;
    height: 0;
    transition: all .2s ease-in-out;
  }
}

.content-section .testimonials.show-all .testimonial:nth-child(n+3) {
  visibility: visible;
  opacity: 1;
  height: auto;
}

@media (width >= 768px) {
  .content-section .testimonials.show-all .testimonial:nth-child(n+8) {
    visibility: visible;
    opacity: 1;
    height: auto;
  }
}

@media (width >= 991px) {
  .content-section .testimonials.show-all .testimonial:nth-child(n+9) {
    visibility: visible;
    opacity: 1;
    height: auto;
  }
}

.content-section .testimonials.show-all .join {
  display: none;
}

.content-section table.comparison {
  border-spacing: 7px 0;
}

@media (width >= 991px) {
  .content-section table.comparison {
    border-spacing: 15px 0;
  }
}

.content-section table.comparison tr:hover, .content-section table.comparison tr:nth-child(2n):hover {
  background-color: #000d28;
}

.content-section table.comparison tr:hover td:nth-child(2), .content-section table.comparison tr:nth-child(2n):hover td:nth-child(2) {
  background-color: #f62439;
}

.content-section table.comparison tr:hover td:nth-child(3), .content-section table.comparison tr:nth-child(2n):hover td:nth-child(3) {
  background-color: #06152d;
}

.content-section table.comparison tr td {
  color: #a1afc9;
  text-transform: uppercase;
  padding: 7px;
  font: 400 12px / 1.2em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .content-section table.comparison tr td {
    font-size: 15px;
  }
}

@media (width >= 991px) {
  .content-section table.comparison tr td {
    font-size: 16px;
  }
}

.content-section table.comparison tr td i {
  font-size: 21px;
}

@media (width >= 768px) {
  .content-section table.comparison tr td i {
    font-size: 24px;
  }
}

@media (width >= 991px) {
  .content-section table.comparison tr td i {
    font-size: 28px;
  }
}

.content-section table.comparison tr td:first-child {
  text-align: left;
  font-weight: 900;
}

.content-section table.comparison tr td:nth-child(2) {
  color: #fff;
  text-shadow: 3px 3px #d5081d;
  background-color: #f61a30;
}

.content-section table.comparison tr td:nth-child(2) i {
  font-size: 29px;
}

@media (width >= 768px) {
  .content-section table.comparison tr td:nth-child(2) i {
    font-size: 34px;
  }
}

@media (width >= 991px) {
  .content-section table.comparison tr td:nth-child(2) i {
    font-size: 42px;
  }
}

.content-section table.comparison tr td:nth-child(3) {
  background-color: #051124;
}

.content-section table.comparison tr td:nth-child(2), .content-section table.comparison tr td:nth-child(3) {
  width: 33.33%;
}

@media (width >= 991px) {
  .content-section table.comparison tr td:nth-child(2), .content-section table.comparison tr td:nth-child(3) {
    width: 35%;
  }
}

.content-section table.comparison tr:nth-child(2n) {
  background-color: #0000;
}

.content-section table.comparison tr:first-child td {
  padding: 25px 0;
  font-weight: 700;
}

@media (width >= 768px) {
  .content-section table.comparison tr:first-child td {
    padding: 30px 10px;
    font-size: 22px;
  }
}

@media (width >= 991px) {
  .content-section table.comparison tr:first-child td {
    font-size: 24px;
  }
}

.content-section table.comparison tr:first-child td:nth-child(2) {
  background: #ed0920;
}

.content-section table.comparison tr:first-child td:nth-child(3) {
  background: #040d1b;
}

.content-section table.comparison tr:last-child td {
  text-transform: none;
  padding: 14px 10px;
}

@media (width >= 768px) {
  .content-section table.comparison tr:last-child td {
    padding: 50px 10px;
    font-size: 15px;
  }
}

@media (width >= 991px) {
  .content-section table.comparison tr:last-child td {
    font-size: 19px;
  }
}

.content-section table.comparison tr:last-child td strong {
  font-size: 22px;
}

@media (width >= 768px) {
  .content-section table.comparison tr:last-child td strong {
    font-size: 40px;
  }
}

@media (width >= 991px) {
  .content-section table.comparison tr:last-child td strong {
    font-size: 50px;
  }
}

@media (width >= 768px) {
  .content-section table.comparison tr:last-child td em {
    font-size: 14px;
  }
}

.content-section table.comparison tr:last-child td:nth-child(2) strong {
  font-size: 26px;
}

@media (width >= 768px) {
  .content-section table.comparison tr:last-child td:nth-child(2) strong {
    font-size: 40px;
  }
}

@media (width >= 991px) {
  .content-section table.comparison tr:last-child td:nth-child(2) strong {
    font-size: 50px;
  }
}

.content-section.customize {
  background: #021422 center / cover no-repeat;
}

.content-section.customize .logo {
  max-width: 350px;
}

@media (width >= 768px) {
  .content-section.customize .logo {
    max-width: 620px;
  }
}

@media (width >= 991px) {
  .content-section.customize .logo {
    max-width: 820px;
  }
}

.content-section.customize .promo-badge {
  max-width: 140px;
}

@media (width >= 768px) {
  .content-section.customize .promo-badge {
    max-width: 200px;
  }
}

@media (width >= 991px) {
  .content-section.customize .promo-badge {
    max-width: 230px;
  }
}

.content-section.customize s {
  opacity: .6;
}

.content-section.customize .horizontal-bonuses .logo {
  max-width: 310px;
}

@media (width >= 768px) {
  .content-section.customize .horizontal-bonuses .logo {
    max-width: 520px;
  }
}

@media (width >= 991px) {
  .content-section.customize .horizontal-bonuses .logo {
    max-width: 770px;
  }
}

.content-section.customize .horizontal-bonuses .logo.edge {
  max-width: 130px;
}

@media (width >= 768px) {
  .content-section.customize .horizontal-bonuses .logo.edge {
    max-width: 190px;
  }
}

@media (width >= 991px) {
  .content-section.customize .horizontal-bonuses .logo.edge {
    max-width: 220px;
  }
}

.content-section.customize .outline-cards {
  width: 100%;
  max-width: 350px;
  padding: 0 7px;
  display: inline-block;
}

@media (width >= 768px) {
  .content-section.customize .outline-cards {
    max-width: 720px;
    padding: 0;
  }
}

@media (width >= 991px) {
  .content-section.customize .outline-cards {
    max-width: 770px;
  }
}

.content-section.customize .outline-cards .float-left {
  float: left;
  width: 100%;
}

@media (width >= 768px) {
  .content-section.customize .outline-cards .float-left {
    width: 50%;
  }
}

.content-section.customize .outline-cards .top-badge {
  background: #f61a30;
  border-radius: 15px 15px 0 0;
  width: 85%;
  top: -22px;
  transform: translate(-50%);
}

@media (width >= 768px) {
  .content-section.customize .outline-cards .top-badge {
    top: -23px;
  }
}

.content-section.customize .outline-cards .card-wrap {
  border: 2px solid;
  border-radius: 15px;
  margin: 0 auto 40px;
  padding: 30px 15px;
  text-decoration: none;
  display: block;
  overflow: hidden;
}

@media (width >= 768px) {
  .content-section.customize .outline-cards .card-wrap {
    margin: 0 auto;
    padding: 40px 25px;
  }
}

.content-section.customize .outline-cards .card-wrap sub {
  margin-right: -40px;
  font-size: 13px;
  bottom: 0;
}

.content-section.customize .outline-cards .card-wrap .join {
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  padding: 11px;
  font-size: 17px;
}

.content-section.customize .outline-cards .card-wrap p {
  margin-bottom: 13px;
}

.content-section.customize .outline-cards .card-wrap p strong {
  font-weight: 900;
}

.content-section.customize .outline-cards .card-wrap p:last-child {
  margin-bottom: 0;
}

.content-section.customize .arrow-wrap {
  text-shadow: 0 0 10px #000000b3;
  font-size: 30px;
}

@media (width >= 768px) {
  .content-section.customize .arrow-wrap {
    font-size: 40px;
  }
}

.content-section.customize .arrow-wrap h3 {
  vertical-align: middle;
  display: inline-block;
}

.content-section.customize .arrow-wrap i {
  margin: 0 25px;
}

@media (width >= 768px) {
  .content-section.customize .arrow-wrap i {
    margin: 0 50px;
  }
}

.content-section.customize .join.bigger {
  width: 100%;
  max-width: 340px;
  margin: 15px auto;
  padding: 20px 10px;
  font-size: 22px;
}

@media (width >= 768px) {
  .content-section.customize .join.bigger {
    max-width: 530px;
    margin: 20px auto;
    padding: 20px;
    font-size: 30px;
  }
}

@media (width >= 991px) {
  .content-section.customize .join.bigger {
    max-width: 720px;
    padding: 25px;
    font-size: 37px;
  }
}

.slick-arrow {
  z-index: 10;
  width: auto;
  height: auto;
  bottom: -50px;
  top: unset;
  background: #0c1017;
  border-radius: 100px;
  margin: 0 5px;
  padding: 3px 12px;
  transition: opacity .3s;
}

@media (width >= 768px) {
  .slick-arrow {
    top: 50%;
    bottom: unset;
    padding: 4px 16px;
  }
}

.slick-arrow:hover, .slick-arrow:focus {
  opacity: 1;
  color: #f61a30;
  background: #1d2739;
}

.slick-arrow.slick-next:before, .slick-arrow.slick-prev:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-variant: normal;
  text-rendering: auto;
  opacity: 1;
  color: #f61a30;
  font-family: "Font Awesome 6 Pro";
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: 1;
  display: inline-block;
}

@media (width >= 768px) {
  .slick-arrow.slick-next:before, .slick-arrow.slick-prev:before {
    font-size: 48px;
  }
}

.slick-arrow.slick-next {
  right: 35%;
}

@media (width >= 768px) {
  .slick-arrow.slick-next {
    right: -25px;
  }
}

.slick-arrow.slick-next:before {
  content: "";
}

.slick-arrow.slick-prev {
  left: 35%;
}

@media (width >= 768px) {
  .slick-arrow.slick-prev {
    left: -25px;
  }
}

.slick-arrow.slick-prev:before {
  content: "";
}

.tooltip {
  position: relative;
}

.tooltip:after, .tooltip:before {
  visibility: hidden;
  opacity: 0;
  height: auto;
  max-height: 0;
  transition: all .3s;
  position: absolute;
  overflow: hidden;
  transform: translate(-50%);
}

.tooltip:before {
  z-index: 100;
  content: "";
  border-top: 7px solid #fff;
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
  bottom: 23px;
  left: 50%;
}

.tooltip:after {
  content: attr(tip);
  text-align: left;
  color: #000;
  background: #fff;
  border-radius: 8px;
  width: 220px;
  padding: 5px 8px;
  font: 400 14px / 1.4em Open Sans, sans-serif;
  bottom: 30px;
  left: -300%;
  box-shadow: 0 0 15px #000;
}

.tooltip:hover, .tooltip:active, .tooltip:focus {
  z-index: 100;
}

.tooltip:hover:after, .tooltip:hover:before, .tooltip:active:after, .tooltip:active:before, .tooltip:focus:after, .tooltip:focus:before {
  visibility: visible;
  opacity: 1;
  max-height: 1000px;
  display: block;
}

.promo-banner.fixed {
  z-index: 97;
  margin-top: 0;
  top: 40px;
}

@media (width >= 768px) {
  .promo-banner.fixed {
    top: 56px;
  }
}

.reveal-overlay {
  z-index: 2147483002;
  background-color: #000c;
  display: none;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.reveal-overlay:after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-variant: normal;
  text-rendering: auto;
  content: "";
  color: #fff;
  z-index: 1;
  opacity: .8;
  text-align: center;
  outline: none;
  width: 35px;
  margin: 0;
  font-family: "Font Awesome 6 Pro";
  font-size: 35px;
  font-style: normal;
  font-weight: 900;
  line-height: 1em;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}

@media (width >= 768px) {
  .reveal-overlay:after {
    width: 50px;
    font-size: 50px;
    top: 7px;
    right: 7px;
  }
}

.reveal-overlay .reveal {
  z-index: 1006;
  backface-visibility: hidden;
  width: 95%;
  max-width: 75rem;
  height: inherit;
  background-color: #fefefe;
  border: none;
  border-radius: 7px;
  outline: none;
  min-height: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  display: none;
  position: relative;
  top: 100px;
  overflow-y: auto;
}

@media (width >= 768px) {
  .reveal-overlay .reveal {
    margin: 0 auto;
    left: auto;
    right: auto;
  }
}

.reveal-overlay .reveal.coach-wrap {
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  border-radius: 10px;
  max-width: 310px;
  overflow: visible;
  top: 40px !important;
}

@media (width >= 768px) {
  .reveal-overlay .reveal.coach-wrap {
    max-width: 450px;
    top: 96px !important;
  }
}

.reveal-overlay .reveal.coach-wrap .bubble {
  padding: 0 5px;
  display: inline-block;
}

@media (width >= 768px) {
  .reveal-overlay .reveal.coach-wrap .bubble {
    padding: 0 10px;
  }
}

.reveal-overlay .reveal.coach-wrap .bubble p {
  text-transform: uppercase;
  margin: 0 auto;
  font: 400 10px / 1.2em Open Sans, sans-serif;
}

.reveal-overlay .reveal.coach-wrap .bubble p i {
  font-size: 12px;
}

@media (width >= 768px) {
  .reveal-overlay .reveal.coach-wrap .bubble p i {
    font-size: 13px;
  }
}

.reveal-overlay .reveal.coach-wrap .bubble p strong {
  color: #000;
  font-size: 13px;
  line-height: 1.2em;
}

@media (width >= 768px) {
  .reveal-overlay .reveal.coach-wrap .bubble p strong {
    font-size: 14px;
  }
}

.reveal-overlay .reveal.coach-wrap .bubble.facebook {
  color: #3b5998;
}

.reveal-overlay .reveal.coach-wrap .bubble.youtube {
  color: #cd201f;
}

.reveal-overlay .reveal.coach-wrap .bubble.instagram {
  color: #e1306c;
}

.reveal-overlay .reveal.coach-wrap .bubble.spotify {
  color: #1db954;
}

.reveal-overlay .reveal.coach-wrap .bubble.grammy {
  color: #b69859;
}

.timeline-line:after {
  content: " ";
  background: #f61a30;
  width: 100%;
  height: 5px;
  position: absolute;
  top: 45px;
  left: 50%;
}

@media (width >= 768px) {
  .timeline-line:after {
    top: 56px;
  }
}

@media (width >= 991px) {
  .timeline-line:after {
    top: 69px;
  }
}
/*# sourceMappingURL=sales-pianote.css.map */
